import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

function We() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();
    return (
        <div className="withdraw px-[15px] py-[20px] min-h-[920px] bg-[#f2f2f2]">
            <div className="bg-[#fff] text-2xl rounded-2xl py-4 px-4 leading-10">
                <span>GIỚI THIỆU</span>
                <br />
                <br />
                <span>Công ty Dịch Thuật & Phiên Dịch Chuyên Nghiệp - Expertrans Global</span>
                <br />
                <br />
                <span>
                    Expertrans Global được biết đến rộng rãi là một trong những công ty hàng đầu chuyên cung cấp các
                    Dịch vụ Ngôn ngữ & Dịch vụ thuê ngoài BPO chuyên nghiệp tại Việt Nam hiện nay. Cho đến nay, các dịch
                    vụ của chúng tôi đã chiếm được lòng tin của hàng ngàn khách hàng từ khắp nơi trên thế giới, mang về
                    vô số những thành quả và góp phần đáng kể vào sự lớn mạnh của thương hiệu Expertrans.
                </span>
                <span>
                    Được thành lập vào năm 2005, sau nhiều năm phát triển đầy ấn tượng, Expertrans tự hào xây dựng một
                    hệ thống gồm nhiều văn phòng đại diện tại Singapore, Thái Lan, Mỹ, Đức, Anh, … với hơn 120 chuyên
                    viên và gần 5,000 cộng tác viên giàu kinh nghiệm trong nhiều lĩnh vực đến từ khắp nơi trên thế giới.{' '}
                </span>
                <span>
                    Thành công của chúng tôi được ghi nhận qua rất nhiều những cột mốc và con số ấn tượng trong suốt hơn
                    1 thập kỷ qua. Expertrans đã thực hiện hơn 30,000 dự án lớn nhỏ trong tất cả các lĩnh vực, cũng như
                    phục vụ hơn 40,000 khách hàng từ nhiều quốc gia như Trung Quốc, Singapore, Hàn Quốc, Pháp, Canada,
                    Mỹ,…
                </span>
                <br />
                <br />
                <span>
                    <b>Các thương hiệu tiêu biểu</b>
                </span>
                <br />
                {/* <span>Tập đoàn tập trung vào bốn phân phúc:</span>
                <br />
                <span>Thực phẩm, Thời trang, Điện tử - Gia dụng và Bất động sản</span>
                <br /> */}
                <br />
                <span>
                    Tại Expertrans, mục tiêu của khách hàng cũng chính là mục tiêu của chúng tôi, và sự uy tín luôn là
                    tiêu chuẩn hàng đầu mà chúng tôi theo đuổi trong tất cả các dự án. Nhờ đó, chúng tôi đã trở thành
                    đối tác chiến lược của rất nhiều các doanh nghiệp, bao gồm cả các thương hiệu toàn cầu lớn, nhờ cung
                    cấp các dịch vụ nền tảng, thuê ngoài BPO, nhân sự và ngôn ngữ hiệu quả để có thể giúp các khách hàng
                    tập trung toàn lực vào giá trị cạnh tranh cốt lõi và tối ưu hóa kinh doanh.
                </span>
                <br />
                <br />
                <span>
                    Bên cạnh đó, Expertrans cũng luôn nỗ lực không ngừng để tạo lập và duy trì một môi trường làm việc
                    lý tưởng cho tất cả nhân viên của chúng tôi trên mọi mặt, từ tuyển dụng và đào tạo nhân tài, cho đến
                    mang lại môi trường làm việc thân thiện như ngôi nhà thứ 2 cùng những lợi ích tuyệt vời nhất cho bộ
                    máy nhân sự.
                </span>
                <br />
                <br />
                <span>
                    Với thôi thúc cải tiến từng ngày, Công ty Dịch Thuật & Phiên Dịch Chuyên Nghiệp - Expertrans Global
                    cam kết thúc đẩy sự phát triển của lối sống và thói quen mua sắm của người tiêu dùng bằng cách giới
                    thiệu không ngừng các hình thức bán lẻ mới phục vụ nhu cầu đa dạng và tạo ra những trải nghiệm đặc
                    biệt vượt trội.
                </span>
                <span>
                    Từ tất cả những kết quả đã đạt được, trong tương lai, Expertrans hi vọng có thể tiếp tục phục vụ và
                    đáp ứng được mọi nhu cầu của khách, hỗ trợ hiệu quả cho các doanh nghiệp và trở thành một trong
                    những nhà cung cấp giải pháp toàn cầu hàng đầu trên thế giới.
                </span>
                {/*
                <br />
                <br />
                <span>
                    Với thông điệp chủ đạo "Mỗi trải nghiệm, mỗi niềm vui", GIGAMALL không chi mang đến trải nghiệm đa
                    dạng và tiện ích phong phú cho khách mua sắm, mà còn có không gian vui chơi thân thiện cho trẻ em,
                    nơi thư giãn giải trí cho cả nhà.
                </span>
                <br />
                <br />
                <span>
                    Để mang lại trải nghiệm mới mẻ cho khách hàng, GIGAMALL tiên phong ứng dụng công nghệ vào các hoạt
                    động dịch vụ và giải trí. Ngay từ sảnh chính tòa nhà, khách tham quan có thể chiêm ngưỡng dàn đèn
                    LED chuyển màu độc đáo kết hợp với sàn tương tác LED hiện đại. Dàn đèn với tên gọi Di.
                </span>
                <br />
                <br />
                <span>
                    Crystal và sàn LED tương tác này là điểm nhấn công nghệ độc đáo, lần đầu tiên có mặt tại Việt Nam,
                    mang đến những màn trình diễn âm thanh kết hợp ánh sáng mãn nhãn cho người xem.
                </span> */}
                {/* <br />
                <br />
                <span>{t('content.we.8')}</span>
                <br />
                <br />
                <span>{t('content.we.9')}</span>
                <br />
                <br />
                <span>{t('content.we.10')}</span>
                <br />
                <br />
                <span>{t('content.we.11')}</span>
                <br />
                <br />
                <span>{t('content.we.12')}</span>
                <br />
                <br />
                <span>{t('content.we.13')}</span> */}
            </div>
        </div>
    );
}

export default We;
